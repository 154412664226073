import { AnonymousLayout, MainLayout } from "../layouts";

import {
  AuthWrapper,
  PageNotFound,
  HomeDashboard,
  CallsList,
  AgentsList,
  CreateAgent,
  TranscriptionsList,
  UpdateAgent,
  LocationList,
  KnowledgeList,
  CreateLocation,
  UpdateLocation,
  CreateKnowledge,
  UpdateKnowledge,
  CreateCalendar,
  UpdateCalendar,
  CalenarList,
  Help,
  Subscription,
  Profile,
} from "../pages";

import HomeIcon from "../assets/images/sidebar/home.svg";
import CallIcon from "../assets/images/sidebar/call.svg";
import VoiceIcon from "../assets/images/sidebar/voice.svg";
import CalendarIcon from "../assets/images/calendar.png";
import BookIcon from "../assets/images/book.png";
import LocationIcon from "../assets/images/location.png";
import invitation from "../assets/images/sidebar/invitation.svg";
import { DiscordLogoIcon } from "@radix-ui/react-icons";

import { renderRoutes } from "./generate-routes";
import { CreatePhone, PhoneList, UpdatePhone } from "../pages/phones";
import { Settings } from "../pages/settings";
import { VirtualStaff } from "../pages/virtualstaff";
import { ResetPassword } from "../pages/reset_password";
import {
  IntegrationList,
  CreateIntegration,
  UpdateIntegration,
} from "../pages/integrations";
import {
  ListUserInvitations,
  CreateUserInvitation,
  UpdateUserInvitation,
} from "../pages/invitation";
import { MasterIntegration } from "../pages/integrations/master.integration";

<source />;

interface Route {
  name: string;
  title: string;
  element: React.ReactElement;
  path: string;
  icon?: React.ReactNode;
  routes?: Route[];
}

interface Layout {
  layout: React.ComponentType<any>;
  isPublic: boolean;
  routes: Route[];
}

export const routes: Layout[] = [
  {
    layout: AnonymousLayout,
    isPublic: true,
    routes: [
      {
        name: "auth",
        title: "Auth page",
        element: <AuthWrapper />,
        path: "/auth",
      },
      {
        name: "page-not-found",
        title: "Page Not Found",
        element: <PageNotFound />,
        path: "*",
      },
      {
        name: "reset-password",
        title: "Reset Password",
        element: <ResetPassword />,
        path: "/reset-password",
        icon: (
          <img src={BookIcon} width={18} height={18} alt="Reset password" />
        ),
        routes: [],
      },
    ],
  },
  {
    layout: MainLayout,
    isPublic: false,
    routes: [
      {
        name: "home",
        title: "Home",
        element: <HomeDashboard />,
        path: "/",
        icon: <img src={HomeIcon} width={20} height={20} alt="home" />,
      },
      {
        name: "calls",
        title: "Calls",
        element: <CallsList />,
        path: "/calls",
        icon: <img src={VoiceIcon} width={20} height={20} alt="home" />,
        routes: [
          {
            name: "transcription",
            title: "Transcription",
            element: <TranscriptionsList />,
            path: "/calls/:id/transcriptions",
          },
        ],
      },
      {
        name: "agents",
        title: "Virtual Employees",
        element: <AgentsList />,
        path: "/agents",
        icon: <DiscordLogoIcon className="text-white" />,
        routes: [
          {
            name: "create-agent",
            title: "Create Agents",
            element: <CreateAgent />,
            path: "/agents/create",
          },
          {
            name: "update-agent",
            title: "Update Agents",
            element: <UpdateAgent />,
            path: "/agents/update",
          },
        ],
      },
      {
        name: "phones",
        title: "Phones",
        element: <PhoneList />,
        path: "/phones",
        icon: <img src={CallIcon} width={20} height={20} alt="home" />,
        routes: [
          {
            name: "create-phones",
            title: "Create Phones",
            element: <CreatePhone />,
            path: "/phones/create",
          },
          {
            name: "update-phones",
            title: "Update Phones",
            element: <UpdatePhone />,
            path: "/phones/update/:id",
          },
        ],
      },
      {
        name: "locations",
        title: "Locations",
        element: <LocationList />,
        path: "/locations",
        icon: <img src={LocationIcon} width={20} height={20} alt="home" />,
        routes: [
          {
            name: "create-location",
            title: "Create Location",
            element: <CreateLocation />,
            path: "/locations/create",
          },
          {
            name: "update-location",
            title: "Update Location",
            element: <UpdateLocation />,
            path: "/locations/update/:id",
          },
        ],
      },
      {
        name: "calendars",
        title: "Calendars",
        element: <CalenarList />,
        path: "/calendars",
        icon: <img src={CalendarIcon} width={20} height={20} alt="home" />,
        routes: [
          {
            name: "create-calendar",
            title: "Create Calendar",
            element: <CreateCalendar />,
            path: "/calendars/create",
          },
          {
            name: "update-calendar",
            title: "Update Calendar",
            element: <UpdateCalendar />,
            path: "/calendars/update/:id",
          },
        ],
      },
      {
        name: "knowledge-bases",
        title: "Knowledge Base",
        element: <KnowledgeList />,
        path: "/knowledge-bases",
        icon: <img src={BookIcon} width={20} height={20} alt="home" />,
        routes: [
          {
            name: "create-knowledge-base",
            title: "Create Knowledge Bases",
            element: <CreateKnowledge />,
            path: "/knowledge-bases/create",
          },
          {
            name: "update-knowledge-base",
            title: "Update Knowledge Bases",
            element: <UpdateKnowledge />,
            path: "/knowledge-bases/update/:id",
          },
        ],
      },
      {
        name: "integrations",
        title: "Integrations",
        element: <IntegrationList />,
        path: "/integrations",
        icon: <img src={BookIcon} width={20} height={20} alt="integrations" />,
        routes: [
          {
            name: "update-integration",
            title: "Update Integration",
            element: <UpdateIntegration />,
            path: "/integrations/update/:id",
          },
          {
            name: "master-integration",
            title: "Master Integration",
            element: <MasterIntegration />,
            path: "/integrations/master-integration",
          },
          {
            name: "create-integration",
            title: "Create Integration",
            element: (
              <CreateIntegration
                integrations={[
                  {
                    system_name: "",
                    description: "",
                    integration_status: "",
                    integration_type: "",
                    required_data: {},
                    data_collected: {},
                  },
                ]}
              />
            ),
            path: "/integrations/create-integration",
          },
        ],
      },
      {
        name: "team-members",
        title: "Team Members",
        element: <ListUserInvitations />,
        path: "/invitations",
        icon: <img src={invitation} width={20} height={20} alt="home" />,
        routes: [
          {
            name: "create-invitation",
            title: "Create Invitation",
            element: <CreateUserInvitation />,
            path: "/invitations/create",
          },
          {
            name: "update-invitation",
            title: "Update Invitation",
            element: <UpdateUserInvitation />,
            path: "/invitations/update/:id",
          },
        ],
      },
      {
        name: "settings",
        title: "Settings",
        element: <Settings />,
        path: "/settings",
        icon: <img src={BookIcon} width={18} height={18} alt="Settings" />,
        routes: [],
      },
      {
        name: "virtualstaff",
        title: "Virtual Employees",
        element: <VirtualStaff />,
        path: "/virtualstaff",
        icon: <img src={BookIcon} width={18} height={18} alt="Virtual Staff" />,
        routes: [],
      },
      {
        name: "help",
        title: "Help",
        element: <Help />,
        path: "/help",
        icon: <img src={BookIcon} width={18} height={18} alt="Help" />,
        routes: [],
      },
      {
        name: "subscription",
        title: "Subscription",
        element: <Subscription />,
        path: "/subscription",
        icon: <img src={BookIcon} width={18} height={18} alt="subscription" />,
        routes: [],
      },
      {
        name: "profile",
        title: "Profile",
        element: <Profile />,
        path: "/profile",
        icon: <img src={BookIcon} width={18} height={18} alt="Profile" />,
        routes: [],
      },
    ],
  },
];

export const renderSidebars = (targetNames: string[]): Route[] => {
  const mainLayout = routes.find((layout) => layout.layout === MainLayout);
  const mainRoutes = mainLayout?.routes || [];
  return mainRoutes.filter((route) => targetNames.includes(route.name));
};

// Generate routes
export const Routes = renderRoutes(routes);
function useStoreState(arg0: (store: any) => any) {
  throw new Error("Function not implemented.");
}
