import { useState } from "react";
import { useTable } from "react-table";
import { ConfirmDialog } from "../dialog";
import { postRequest } from "../../react-query";
import { toast } from "react-toastify";

export function NormalDataGrid({
  activeTab,
  columns,
  actionList,
  data,
  onRowClick,
}: any) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<string>();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const renderMakeCallUI = (id: string) => {
    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="bg-green text-perl px-2 rounded-lg"
      >
        Test
      </button>
    );
  };

  const [input, setInput] = useState({
    testNumber: "",
    countryCode: "",
  });

  const [errors, setErrors] = useState({
    testNumber: "",
  });

  const onUpdateSubmit = (id: string) => {
    if (input.testNumber !== "" && input.countryCode !== "") {
      try {
        postRequest({
          endPoint: `/twilio/call`,
          payload: {
            to_number: `${input.countryCode}${input.testNumber}`,
            agent_id: id,
            phone_number_id: "ab8d1df1-300d-46d4-8206-20808aae77ae",
          },
        });
        toast.success("Your request is successfully submitted");
        setIsOpen(!isOpen);
      } catch (error) {}
    } else {
      setErrors({ ...errors, testNumber: "Required field" });
    }
  };

  const onChangeHandler = (value: string, name: string) => {
    setInput({ ...input, [name]: value });
  };

  return (
    <div className="shadow overflow-hidden border-b border-t border-header-border">
      <div className="overflow-y-auto max-h-[600px]">
        <table
          {...getTableProps()}
          className="min-w-full divide-y divide-header-border"
        >
          <thead className="bg-transparent bg-opacity-50 sticky top-0">
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column: any, index2: number) => (
                  <th
                    key={index2}
                    scope="col"
                    className="group py-3 text-left font-bold text-perl capitalize tracking-wider"
                    {...column.getHeaderProps()}
                  >
                    <div className="flex items-center justify-between">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="relative bg-transparent divide-y divide-header-border"
          >
            {rows.map((row: any, i: any) => {
              prepareRow(row);
              return (
                <>
                  <tr
                    onMouseEnter={() => setShowDelete(row.original.id)}
                    onMouseLeave={() => setShowDelete("")}
                    className="cursor-pointer hover:bg-[#424244]"
                    onClick={() => onRowClick(row.original)}
                    {...row.getRowProps()}
                    key={i}
                  >
                    {row.cells.map((cell: any, index: number) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="py-3 whitespace-nowrap"
                          role="cell"
                          key={index}
                        >
                          {actionList.includes(activeTab) &&
                          showDelete === row.original.id &&
                          cell.column.Header === "Call"
                            ? renderMakeCallUI(row.original.id)
                            : cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>

                  <ConfirmDialog
                    testNumber={input.testNumber}
                    onChangeHandler={onChangeHandler}
                    isOpen={isOpen}
                    error={errors.testNumber}
                    onClose={() => setIsOpen(!isOpen)}
                    onConfirm={() => {
                      onUpdateSubmit(row.original.id);
                    }}
                  />
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
